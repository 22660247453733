import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { HELP_MENU_ID, USER_MENU_ID } from '../../../utils/constants';
import HelpIcon from '@material-ui/icons/Help';
import { useNavbarStyles } from './AppNavbar.style';
import { DevContext } from '../../../App';
import Box from '@material-ui/core/Box';

//TODO: clean code
const NavbarSections = (props) => {
    const classes = useNavbarStyles();
    const isDevMode = useContext(DevContext);

    // //TODO: will be moved
    // const role = useSelector(selectRole);

    return (
        <React.Fragment>
            <div className={classes.sectionDesktop}>
                <IconButton
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={HELP_MENU_ID}
                    aria-haspopup='true'
                    onClick={props.helpMenuClicked}
                    color='inherit'>
                    <HelpIcon />
                </IconButton>
                <Box ml={2} mt={2}>
                    {props.loggedInUser ? props.loggedInUser.full_name : ''}
                </Box>
                <IconButton
                    edge='end'
                    aria-label='help'
                    aria-controls={USER_MENU_ID}
                    aria-haspopup='true'
                    onClick={props.profileMenuClicked}
                    color='inherit'>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </div>
            <div className={classes.sectionMobile}>
                <IconButton
                    edge='end'
                    aria-label='account of current user'
                    aria-controls={HELP_MENU_ID}
                    aria-haspopup='true'
                    onClick={props.helpMenuClicked}
                    color='inherit'>
                    <HelpIcon />
                </IconButton>
                <Box ml={2} mt={2}>
                    {props.loggedInUser ? props.loggedInUser.full_name : ''}
                </Box>
                <IconButton
                    edge='end'
                    aria-label='help'
                    aria-controls={USER_MENU_ID}
                    aria-haspopup='true'
                    onClick={props.profileMenuClicked}
                    color='inherit'>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </div>
            {/*{role === 'team' ? (*/}
            {/*    <>*/}
            {/*        <NavbarMessages />*/}
            {/*        <NavbarNotifications />*/}
            {/*    </>*/}
            {/*) : null}*/}
        </React.Fragment>
    );
};

export default NavbarSections;
