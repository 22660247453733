import React from 'react';

import SvgIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import { ReactComponent as Filled11 } from '../../../../../assets/images/teeth/11_filled.svg';
import { ReactComponent as Outlined11 } from '../../../../../assets/images/teeth/11_outlined.svg';
import { ReactComponent as Filled12 } from '../../../../../assets/images/teeth/12_filled.svg';
import { ReactComponent as Outlined12 } from '../../../../../assets/images/teeth/12_outlined.svg';
import { ReactComponent as Filled13 } from '../../../../../assets/images/teeth/13_filled.svg';
import { ReactComponent as Outlined13 } from '../../../../../assets/images/teeth/13_outlined.svg';
import { ReactComponent as Filled14 } from '../../../../../assets/images/teeth/14_filled.svg';
import { ReactComponent as Outlined14 } from '../../../../../assets/images/teeth/14_outlined.svg';
import { ReactComponent as Filled15 } from '../../../../../assets/images/teeth/15_filled.svg';
import { ReactComponent as Outlined15 } from '../../../../../assets/images/teeth/15_outlined.svg';
import { ReactComponent as Filled16 } from '../../../../../assets/images/teeth/16_filled.svg';
import { ReactComponent as Outlined16 } from '../../../../../assets/images/teeth/16_outlined.svg';
import { ReactComponent as Filled17 } from '../../../../../assets/images/teeth/17_filled.svg';
import { ReactComponent as Outlined17 } from '../../../../../assets/images/teeth/17_outlined.svg';
import { ReactComponent as Filled18 } from '../../../../../assets/images/teeth/18f.svg';
import { ReactComponent as Outlined18 } from '../../../../../assets/images/teeth/18.svg';

import { ReactComponent as Filled21 } from '../../../../../assets/images/teeth/21_filled.svg';
import { ReactComponent as Outlined21 } from '../../../../../assets/images/teeth/21_outlined.svg';
import { ReactComponent as Filled22 } from '../../../../../assets/images/teeth/22_filled.svg';
import { ReactComponent as Outlined22 } from '../../../../../assets/images/teeth/22_outlined.svg';
import { ReactComponent as Filled23 } from '../../../../../assets/images/teeth/23_filled.svg';
import { ReactComponent as Outlined23 } from '../../../../../assets/images/teeth/23_outlined.svg';
import { ReactComponent as Filled24 } from '../../../../../assets/images/teeth/24_filled.svg';
import { ReactComponent as Outlined24 } from '../../../../../assets/images/teeth/24_outlined.svg';
import { ReactComponent as Filled25 } from '../../../../../assets/images/teeth/25_filled.svg';
import { ReactComponent as Outlined25 } from '../../../../../assets/images/teeth/25_outlined.svg';
import { ReactComponent as Filled26 } from '../../../../../assets/images/teeth/26_filled.svg';
import { ReactComponent as Outlined26 } from '../../../../../assets/images/teeth/26_outlined.svg';
import { ReactComponent as Filled27 } from '../../../../../assets/images/teeth/27_filled.svg';
import { ReactComponent as Outlined27 } from '../../../../../assets/images/teeth/27_outlined.svg';
import { ReactComponent as Filled28 } from '../../../../../assets/images/teeth/28_filled.svg';
import { ReactComponent as Outlined28 } from '../../../../../assets/images/teeth/28_outlined.svg';

import { ReactComponent as Filled31 } from '../../../../../assets/images/teeth/31_filled.svg';
import { ReactComponent as Outlined31 } from '../../../../../assets/images/teeth/31_outlined.svg';
import { ReactComponent as Filled32 } from '../../../../../assets/images/teeth/32_filled.svg';
import { ReactComponent as Outlined32 } from '../../../../../assets/images/teeth/32_outlined.svg';
import { ReactComponent as Filled33 } from '../../../../../assets/images/teeth/33_filled.svg';
import { ReactComponent as Outlined33 } from '../../../../../assets/images/teeth/33_outlined.svg';
import { ReactComponent as Filled34 } from '../../../../../assets/images/teeth/34_filled.svg';
import { ReactComponent as Outlined34 } from '../../../../../assets/images/teeth/34_outlined.svg';
import { ReactComponent as Filled35 } from '../../../../../assets/images/teeth/35_filled.svg';
import { ReactComponent as Outlined35 } from '../../../../../assets/images/teeth/35_outlined.svg';
import { ReactComponent as Filled36 } from '../../../../../assets/images/teeth/36_filled.svg';
import { ReactComponent as Outlined36 } from '../../../../../assets/images/teeth/36_outlined.svg';
import { ReactComponent as Filled37 } from '../../../../../assets/images/teeth/37_filled.svg';
import { ReactComponent as Outlined37 } from '../../../../../assets/images/teeth/37_outlined.svg';
import { ReactComponent as Filled38 } from '../../../../../assets/images/teeth/38_filled.svg';
import { ReactComponent as Outlined38 } from '../../../../../assets/images/teeth/38_outlined.svg';

import { ReactComponent as Filled41 } from '../../../../../assets/images/teeth/41_filled.svg';
import { ReactComponent as Outlined41 } from '../../../../../assets/images/teeth/41_outlined.svg';
import { ReactComponent as Filled42 } from '../../../../../assets/images/teeth/42_filled.svg';
import { ReactComponent as Outlined42 } from '../../../../../assets/images/teeth/42_outlined.svg';
import { ReactComponent as Filled43 } from '../../../../../assets/images/teeth/43_filled.svg';
import { ReactComponent as Outlined43 } from '../../../../../assets/images/teeth/43_outlined.svg';
import { ReactComponent as Filled44 } from '../../../../../assets/images/teeth/44_filled.svg';
import { ReactComponent as Outlined44 } from '../../../../../assets/images/teeth/44_outlined.svg';
import { ReactComponent as Filled45 } from '../../../../../assets/images/teeth/45_filled.svg';
import { ReactComponent as Outlined45 } from '../../../../../assets/images/teeth/45_outlined.svg';
import { ReactComponent as Filled46 } from '../../../../../assets/images/teeth/46_filled.svg';
import { ReactComponent as Outlined46 } from '../../../../../assets/images/teeth/46_outlined.svg';
import { ReactComponent as Filled47 } from '../../../../../assets/images/teeth/47_filled.svg';
import { ReactComponent as Outlined47 } from '../../../../../assets/images/teeth/47_outlined.svg';
import { ReactComponent as Filled48 } from '../../../../../assets/images/teeth/48_filled.svg';
import { ReactComponent as Outlined48 } from '../../../../../assets/images/teeth/48_outlined.svg';

//250 ---> 400
//height ---> 2em
export const getUncheckedTeethIcon = (value) => {
    switch (value) {
        case '11':
            return (
                <SvgIcon
                    component={Outlined11}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '12':
            return (
                <SvgIcon
                    component={Outlined12}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '13':
            return (
                <SvgIcon
                    component={Outlined13}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '14':
            return (
                <SvgIcon
                    component={Outlined14}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '15':
            return (
                <SvgIcon
                    component={Outlined15}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '16':
            return (
                <SvgIcon
                    component={Outlined16}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '17':
            return (
                <SvgIcon
                    component={Outlined17}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '18':
            return (
                <SvgIcon
                    component={Outlined18}
                    viewBox='0 0 500 500'
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
            );
        case '21':
            return (
                <SvgIcon
                    component={Outlined21}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '22':
            return (
                <SvgIcon
                    component={Outlined22}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '23':
            return (
                <SvgIcon
                    component={Outlined23}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '24':
            return (
                <SvgIcon
                    component={Outlined24}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '25':
            return (
                <SvgIcon
                    component={Outlined25}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '26':
            return (
                <SvgIcon
                    component={Outlined26}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '27':
            return (
                <SvgIcon
                    component={Outlined27}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '28':
            return (
                <SvgIcon
                    component={Outlined28}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '31':
            return (
                <SvgIcon
                    component={Outlined31}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '32':
            return (
                <SvgIcon
                    component={Outlined32}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '33':
            return (
                <SvgIcon
                    component={Outlined33}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '34':
            return (
                <SvgIcon
                    component={Outlined34}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '35':
            return (
                <SvgIcon
                    component={Outlined35}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '36':
            return (
                <SvgIcon
                    component={Outlined36}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '37':
            return (
                <SvgIcon
                    component={Outlined37}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '38':
            return (
                <SvgIcon
                    component={Outlined38}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '41':
            return (
                <SvgIcon
                    component={Outlined41}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '42':
            return (
                <SvgIcon
                    component={Outlined42}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '43':
            return (
                <SvgIcon
                    component={Outlined43}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '44':
            return (
                <SvgIcon
                    component={Outlined44}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '45':
            return (
                <SvgIcon
                    component={Outlined45}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '46':
            return (
                <SvgIcon
                    component={Outlined46}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '47':
            return (
                <SvgIcon
                    component={Outlined47}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        case '48':
            return (
                <SvgIcon
                    component={Outlined48}
                    viewBox='0 0 250 700'
                    style={{
                        height: '100%',
                    }}
                />
            );
        default:
            return <ShoppingCartIcon />;
    }
    return <ShoppingCartIcon />;
};

export const getCheckedTeethIcon = (value) => {
    switch (value) {
        case '11':
            return <SvgIcon component={Filled11} viewBox='0 0 250 700' />;
        case '12':
            return <SvgIcon component={Filled12} viewBox='0 0 250 700' />;
        case '13':
            return <SvgIcon component={Filled13} viewBox='0 0 250 700' />;
        case '14':
            return <SvgIcon component={Filled14} viewBox='0 0 250 700' />;
        case '15':
            return <SvgIcon component={Filled15} viewBox='0 0 250 700' />;
        case '16':
            return <SvgIcon component={Filled16} viewBox='0 0 250 700' />;
        case '17':
            return <SvgIcon component={Filled17} viewBox='0 0 250 700' />;
        case '18':
            return <SvgIcon component={Filled18} viewBox='0 0 500 500'                     style={{
                height: '100%',
                width: '100%',
            }}/>;
        case '21':
            return <SvgIcon component={Filled21} viewBox='0 0 250 700' />;
        case '22':
            return <SvgIcon component={Filled22} viewBox='0 0 250 700' />;
        case '23':
            return <SvgIcon component={Filled23} viewBox='0 0 250 700' />;
        case '24':
            return <SvgIcon component={Filled24} viewBox='0 0 250 700' />;
        case '25':
            return <SvgIcon component={Filled25} viewBox='0 0 250 700' />;
        case '26':
            return <SvgIcon component={Filled26} viewBox='0 0 250 700' />;
        case '27':
            return <SvgIcon component={Filled27} viewBox='0 0 250 700' />;
        case '28':
            return <SvgIcon component={Filled28} viewBox='0 0 250 700' />;
        case '31':
            return <SvgIcon component={Filled31} viewBox='0 0 250 700' />;
        case '32':
            return <SvgIcon component={Filled32} viewBox='0 0 250 700' />;
        case '33':
            return <SvgIcon component={Filled33} viewBox='0 0 250 700' />;
        case '34':
            return <SvgIcon component={Filled34} viewBox='0 0 250 700' />;
        case '35':
            return <SvgIcon component={Filled35} viewBox='0 0 250 700' />;
        case '36':
            return <SvgIcon component={Filled36} viewBox='0 0 250 700' />;
        case '37':
            return <SvgIcon component={Filled37} viewBox='0 0 250 700' />;
        case '38':
            return <SvgIcon component={Filled38} viewBox='0 0 250 700' />;
        case '41':
            return <SvgIcon component={Filled41} viewBox='0 0 250 700' />;
        case '42':
            return <SvgIcon component={Filled42} viewBox='0 0 250 700' />;
        case '43':
            return <SvgIcon component={Filled43} viewBox='0 0 250 700' />;
        case '44':
            return <SvgIcon component={Filled44} viewBox='0 0 250 700' />;
        case '45':
            return <SvgIcon component={Filled45} viewBox='0 0 250 700' />;
        case '46':
            return <SvgIcon component={Filled46} viewBox='0 0 250 700' />;
        case '47':
            return <SvgIcon component={Filled47} viewBox='0 0 250 700' />;
        case '48':
            return <SvgIcon component={Filled48} viewBox='0 0 250 700' />;
        default:
            return <ShoppingCartIcon />;
    }
    return <ShoppingBasketIcon />;
};
