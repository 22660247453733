import { Button } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import go_to_assist from '../../../assets/images/go_to_assist.png';
import team_viewer from '../../../assets/images/team_viewer.png';

const NavbarSupport = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const onLogout = () => history.push('/logout');
    return (
        <React.Fragment>
            <div>
                <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    onClick={onLogout}
                    startIcon={<LogoutIcon />}
                    >
                        {t('routes.auth.logout')}
                </Button>            
            </div>  
        </React.Fragment>
    );
};

export default NavbarSupport;


